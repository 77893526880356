import BaseCtrl from 'edge/platform/base/BaseCtrl';
import _ from 'underscore';

const fn = ($filter, $scope, StateUtils, submissionViewModel, CustomQuoteService, RenewalService_AND, ViewModelService, ViewModelContext, ScrollService_AND, $state,  $q, StaticDocumentsService_AND) => {
    const ctrl = Object.create(BaseCtrl);
    const flowModel = StateUtils.activeDataForState(true).flowModel;
    const draftSubmissionVM = submissionViewModel();
    let isVolExcessChanged = false;

    const retrieveDocs = () => $q.all([
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_POLICY),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.PRIVACY_NOTICE),
        StaticDocumentsService_AND.getDocURL(StaticDocumentsService_AND.DOCS.COOKIE_POLICY),
    ])
    .then((result) => {
        $scope.privacyPolicyDoc = result[0];
        $scope.privacyNoticeDoc = result[1];
        $scope.cookiePolicyDoc = result[2];
    });
    const retrieveDocsPromise = retrieveDocs();

    const setHiddenVersion = function (quotes) {
        if (quotes.length === 1) {
            return;
        }
        for (let i = 0; i < quotes.length; i++) {
            if (quotes[i].isCustom === true) {
                switch (i) {
                    case 0:
                    case 1:
                        quotes[2].isHidden = true;
                        break;
                    case 2:
                    default:
                        quotes[0].isHidden = true;
                }
            }
        }
    };

    const getUnadjustedAmount = (amount = {amount: 0}) => Math.floor((amount.amount / 12) * 100) / 100;

    const getMonthlyAmount = (cov) => Object.assign(
        {},
        cov.amount || {currency: 'gbp', amount: 0},
        {amount: getUnadjustedAmount(cov.amount)} // Do not round
    );

    const getBaseOffering = (quotes) => quotes.find((quote) => quote.isCustom === true);

    const updateModel = (qdd) => {
        if (!qdd.quoteData) { // Something went wrong, go to error page
            $state.go('contactUs');
        }
        $scope.page = Object.assign({}, $scope.page);
        // Update scope variables
        $scope.page.offeredQuotes = qdd.quoteData.offeredQuotes;
        $scope.page.baseOffering = getBaseOffering($scope.page.offeredQuotes);
        $scope.page.voluntaryExcess = qdd.lobData.personalMotor.coverables.pmVehicles[0].voluntaryExcess;
        $scope.page.ncdProtection = qdd.lobData.personalMotor.coverables.pmVehicles[0].ncdProtection;
        // Don't change the object reference as it's not being picked up correctly downstream.
        $scope.page.ncdCosts = Object.assign($scope.page.ncdCosts ? $scope.page.ncdCosts : {}, qdd.lobData.personalMotor.coverables.pmVehicles[0].ncdCosts);
        $scope.page.submission.value.bindData = qdd.bindData;
        // Update
        setHiddenVersion($scope.page.offeredQuotes);
        return $scope.page;
    };

    const getCoverage = (patternCode, offering = $scope.page.baseOffering) => offering.lobs.personalMotor
        .pmVehicleCoverages[0].coverages.find((cov) => cov.codeIdentifier === patternCode);

    const getCoverageTermFromCoverage = (coverage, covTermPatternCode) =>
        coverage.terms.find((term) => term.patternCode === covTermPatternCode);

    const getCoverageTerm = (covPatternCode, covTermPatternCode, offering = $scope.page.baseOffering) => {
        const coverage = getCoverage(covPatternCode, offering);
        if (coverage) {
            return getCoverageTermFromCoverage(coverage, covTermPatternCode);
        }
    };

    const updateQuote = function () {
        $scope.page.updateInFlight = true;
        return handleQuotingError(()=> RenewalService_AND.updateCoverages({
            sessionUUID: $scope.page.sessionUUID,
            quoteID: $scope.page.quoteID,
            coverages: $scope.page.baseOffering.lobs,
            quote: $scope.page.baseOffering,
            voluntaryExcess: $scope.page.voluntaryExcess, // Set in the PmQuoteExcess directive
            periodStartDate: $scope.page.periodStartDate ,// Set in the PmQuoteReviewCover directive
            ncdProtection: $scope.page.ncdProtection,
            ncdCosts: $scope.page.ncdCosts
        })
            .then((qdd) => {
                qdd.quoteData.offeredQuotes = qdd.quoteData.offeredQuotes.map(quote => {
                    // from Submission model - include coverages in quote object
                    quote.lobs = _.mapObject(qdd.lobData, lobdata => {
                        if (lobdata.coverages) {
                            return lobdata.coverages;
                        }
                        if (lobdata.offerings) {
                            const offering = lobdata.offerings.find(offer => offer.branchName === quote.branchName);
                            return offering ? offering.coverages : null;
                        }
                    });
                    return quote;
                });
                return updateModel(qdd);
            })
            .finally(() => {
                $scope.page.updateInFlight = false;
            }));
    };

    const updateCoverageSelection = function (selected, patternCode) {
        $scope.page.offeredQuotes.forEach((quote) => {
            getCoverage(patternCode, quote).selected = selected;
        });
        return updateQuote();
    };

    const updateCoverageTermSelection = function (chosenTerm, covPatternCode, covTermPatternCode) {
        $scope.page.offeredQuotes.forEach((quote) => {
            const coverage = getCoverage(covPatternCode, quote);
            coverage.selected = chosenTerm !== null;
            const covTerm = getCoverageTermFromCoverage(coverage, covTermPatternCode);
            covTerm.chosenTerm = chosenTerm;
            covTerm.updated = true; // Required to trigger update in PC
        });
        return updateQuote();
    };

    const handleQuotingError = function (promiseFn) {
        return promiseFn()
            .catch((error) => {
                error = error[0];
                if (error && error.baseError && error.baseError.error && error.baseError.error.data) {
                    switch (error.baseError.error.data.appErrorCode) {
                        case 602: // GW_UNDERWRITING_ERROR
                        case 631: // AND_HIGH_RISK_VALIDATION_EXCEPTION
                            flowModel.jumpToExit('uwError_AND')
                            break;
                        case 608: // GW_ENTITY_VALIDATION_ERROR
                        case 611: // GW_BLOCK_QUOTE_UNDERWRITING_ERROR
                            flowModel.jumpToExit('quoteDeclined_AND');
                            break;
                        case 624: // AND_CUE_ENTITY_VALIDATION_ERROR
                            flowModel.jumpToExit('quoteDeclinedCue_AND');
                            break;
                        case 629: // 20K_UW_EXCEPTION
                            flowModel.jumpToExit('callServiceCenter');
                            break;
                        default: // Technical error
                            flowModel.jumpToExit('error');
                    }
                } else { // Technical error
                    flowModel.jumpToExit('error');
                }
            });
    };

    const goToQuotePage = () => { // The actual quote page
        $scope.pageView = 'quotePage';
        flowModel.isAllStepsDisabled_AND = $scope.page ? $scope.page.submission.isBase_itb.value === true : true;
        ScrollService_AND.scrollToTop();
    };
    const goToQuotingPage = () => { // Quoting loader animation
        $scope.pageView = 'quoting';
        flowModel.isAllStepsDisabled_AND = true;
    };
    const goToCoverReviewPage = function () { // Review page for cover section
        $scope.pageView = 'coverReviewPage';
        flowModel.isAllStepsDisabled_AND = true;
        ScrollService_AND.scrollToTop();
    };
    /**
     * Use this to disable any action elements. E.g. when dealing with the renewal base version the entire page
     * should be readonly; when saving to backend actions should be disable to avoid an inconsistent state.
     * @returns {boolean}
     */
    const controlsDisabled = function () {
        return $scope.page.submission.isBase_itb.value === true || $scope.page.updateInFlight === true;
    };

    const getIsVolExcessChanged = function() {
        return isVolExcessChanged;
    }

    const setIsVolExcessChanged = function (value) {
        isVolExcessChanged = value;
    }

    const initQuotePage = function (quotedSubmissionVM) {
        $scope.page = {
            submission: quotedSubmissionVM,
            updateCoverageSelection, // When selection has to be reflected on all quotes
            updateCoverageTermSelection, // When selection has to be reflected on all quotes
            updateQuote, // When it doesn't
            getCoverage,
            getCoverageTerm,
            getMonthlyAmount,
            updateInFlight: false,
            quoteID: quotedSubmissionVM.quoteID.value,
            sessionUUID: quotedSubmissionVM.sessionUUID.value,
            goToCoverReviewPage,
            goToQuotePage,
            goToQuotingPage,
            controlsDisabled,
            getIsVolExcessChanged,
            setIsVolExcessChanged
        }; // Known as quoteData downstream
        updateModel(quotedSubmissionVM.value);
    };

    ctrl.init = () => {
        // Do not requote if already quoted
        if (draftSubmissionVM.baseData.periodStatus.value.code === 'Quoted' || draftSubmissionVM.baseData.periodStatus.value.code === 'Renewing') {
            initQuotePage(ViewModelService.create(draftSubmissionVM.value, 'pc', 'itb.edge.capabilities.renewal.dto.RenewalDataDTO', ViewModelContext));
            goToQuotePage();
        } else {
            goToQuotingPage();
            handleQuotingError(() => RenewalService_AND.quote(draftSubmissionVM.value)
                .then(submissionDTO => {
                    initQuotePage(ViewModelService.create(submissionDTO, 'pc', 'itb.edge.capabilities.renewal.dto.RenewalDataDTO', ViewModelContext));
                })
                .finally(() => {
                    goToQuotePage();
                }));
        }
    };

    ctrl.error = (data) => {
        flowModel.jumpToExit('error', data);
    };

    return ctrl.create($scope);
};
fn.$inject = ['$filter', '$scope', 'StateUtils', 'submissionViewModel', 'qnb.CustomQuoteService', 'RenewalService_AND',
    'ViewModelService', 'qnb.ViewModelCtx', 'ScrollService_AND', '$state', '$q', 'StaticDocumentsService_AND'];

export default fn;